<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Vendors" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="vendors.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              @click="exportExcel"
              :disabled="exporting"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <!-- <md-table-cell md-label="Name">{{ item.user.name }}</md-table-cell> -->
          <!-- <md-table-cell md-label="Email">{{ item.user.email }}</md-table-cell> -->
          <md-table-cell md-label="Phone number"
            >{{ item.vendor_phone_ext || (item.user && item.user.phone_ext)
            }}{{
              item.vendor_phone || (item.user && item.user.phone)
            }}</md-table-cell
          >
          <md-table-cell md-label="Shop name">{{
            item.shop_name
          }}</md-table-cell>

          <md-table-cell md-label="Company">{{
            item.company_name
          }}</md-table-cell>
          <md-table-cell md-label="Tracking code">{{
            item.tracking_code
          }}</md-table-cell>
          <md-table-cell md-label="Registered By">
            <div class="badge yellow">
              {{
                item.register
                  ? item.register.user.first_name +
                    " " +
                    item.register.user.last_name
                  : "User"
              }}
            </div>
          </md-table-cell>
          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <!-- <md-menu-item
                  v-if="item.promote_count == 0"
                  @click="promote(item)"
                  >Promote <i class="material-icons">arrow_upward</i>
                </md-menu-item>
                <md-menu-item v-else @click="promote(item, 'update')"
                  >Update Promotion
                </md-menu-item> -->
                <md-menu-item @click="setVendor(item)">Preview</md-menu-item>
                <div v-if="hasPermission">
                  <md-menu-item
                    v-if="
                      ['disapproved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeHandymanStatus(item.id, 'approved')"
                    >Approve</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      ['approved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeHandymanStatus(item.id, 'disapproved')"
                    >Disapprove</md-menu-item
                  >
                  <md-menu-item>Delete </md-menu-item>
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="vendors.mdPage"
        :records="vendors.mdCount"
        :per-page="vendors.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Vendor Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="vendor">
        <div v-if="vendor.user">
          <div class="">
            <strong>Logo</strong>
          </div>
          <br />
          <div class="media">
            <div class="logo">
              <img
                :src="getStorageUrl(vendor.logo)"
                onerror="this.src='https://via.placeholder.com/220x150?text=No%20Image'"
                alt="Display Image"
              />
            </div>
          </div>

          <div class="d-section">
            <div class="header">
              <h4>Personal Details</h4>
            </div>
            <div class="item">
              <strong>Full name:</strong>
              <span> {{ vendor.user.name }}</span>
            </div>

            <div class="item">
              <strong>Email Address:</strong>
              <span> {{ vendor.user.email }}</span>
            </div>

            <div class="item">
              <strong>Phone Number:</strong>
              <a :href="`tel:${vendor.user.phone_ext + vendor.user.phone}`">
                {{ vendor.user.phone_ext + vendor.user.phone }}</a
              >
            </div>

            <div class="item">
              <strong>Date of Birth:</strong>
              <span> {{ vendor.dob }}</span>
            </div>
          </div>

          <div class="d-section">
            <div class="header">
              <h4>Business Details</h4>
            </div>
            <div class="item">
              <strong>Shop name:</strong>
              <span> {{ vendor.shop_name }}</span>
            </div>
            <div class="item">
              <strong>Business Type:</strong>
              <span> {{ vendor.business_type }}</span>
            </div>
            <div class="item">
              <strong>Shop or House number:</strong>
              <span> --</span>
            </div>
            <div class="item">
              <strong>Shop or Business address:</strong>
              <span>
                {{ vendor.address }},
                <span v-if="vendor.state">{{ vendor.state.title }}</span></span
              >
            </div>
            <div class="item">
              <strong>Business description:</strong>
              <span v-html="vendor.about"> </span>
            </div>
          </div>

          <div class="d-section">
            <div class="header">
              <h4>Document Upload</h4>
            </div>

            <div class="item">
              <strong>Valid ID Card:</strong>
              <a
                v-if="vendor.id_card"
                target="_blank"
                :href="getStorageUrl(vendor.id_card)"
                >View Document</a
              >
              <a v-else>NIL</a>
            </div>

            <div class="item">
              <strong>CAC Certificate:</strong>
              <span>
                <a
                  v-if="vendor.cac_certificate_upload"
                  :href="vendor.cac_certificate_upload"
                  >View Document</a
                >
                <a v-else>NIL</a>
              </span>
            </div>

            <div class="item">
              <strong>Tax Certificate:</strong>
              <span> {{ vendor.tin }}</span>
            </div>
          </div>

          <div class="d-section">
            <div class="header">
              <h4>Document ID Number:</h4>
            </div>

            <div class="item">
              <strong>CAC Certificate Number:</strong>
              <span> {{ vendor.cac_reg_number }}</span>
            </div>

            <div class="item">
              <strong>Tax ID Number:</strong>
              <span> {{ vendor.tin }}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info">User Object not found</div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("vendor");
export default {
  name: "Vendor",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      show: false,
      time: null,
      vendor: null,
      exporting: false,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getVendors", "updateVendorStatus"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getVendors(data);
    },
    async changeHandymanStatus(id, status) {
      try {
        this.updating = true;
        await this.updateVendorStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Vendor Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async submitSearch() {
      await this.getVendors({ keyword: this.search });
    },
    async promote(item, mode = "create") {
      // this.$eventBus.$emit("promote", {
      //   item_type: this.promotions.VENDOR,
      //   item_id: item.id,
      //   title: item.company_name,
      //   promote: item.promote,
      //   mode,
      // });
    },
    async exportExcel() {
      this.exporting = true;
      try {
        await this.$store.dispatch("auth/export", { model: "vendor" });
      } catch (err) {
        this.toast("error", "Unable to Export");
      } finally {
        this.exporting = false;
      }
    },
    setVendor(vendor) {
      this.show = true;
      this.vendor = vendor;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },
  created() {},
  mounted() {
    this.getVendors();
  },
  computed: {
    ...mapGetters(["vendors", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}
</style>
